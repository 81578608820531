<template>
  <div class="modal__content">
    <h3 style="margin-bottom: 10px">УВАЖАЕМЫЕ ВОДИТЕЛИ!</h3>
    <p>
      Уведомляем вас о том, что согласно законодательству РФ наша компания имеет
      право предоставлять заказы
      <strong
        >только водителям с действующим статусом Индивидуального
        предпринимателя.</strong
      >
    </p>
    <p style="margin-bottom: 10px">
      В 2023 мы должны выполнить это требование в полном объеме до
      <strong>01.03.2023</strong> го Физ. лицо даже в статусе самозанятого не
      может быть «перевозчиком». Может быть только наёмным сотрудником. Но наша
      форма сотрудничества не предполагает оформление по ТК, так как мы не
      являемся работодателем, а являемся партнером по предоставлению заказов.
      Поэтому мы разработали подробную инструкцию, как вам открыть ИП, стать
      более привлекательным на рынке грузоперевозок и сэкономить на налогах:
    </p>
    <ul style="margin-bottom: 20px; padding-left: 20px">
      <li>
        <p>
          В статусе ИП при перечислении вам денег не будет удерживаться сумма
          банковской комиссии за перевод физ.лицу 5%.
        </p>
      </li>
      <li>Сэкономленную сумму 5% вы будете использовать на оплату налога.</li>
      <li>
        Налоги у ИП в том числе отчисляются в пенсионный фонд, что повлияет на
        вашу будущую пенсию. В отличии от самозанятого или без статуса
        налогоплательщика обычного физ.лица.
      </li>
      <li>
        Пенсионными налогами у ИП можно уменьшить основной налог с дохода. У ИП
        два вида платежей: Это пенсионные около ~ 40т.р за год и второй вид
        налога это по выбранной системе налогообложения - 6% со всего дохода (в
        льготных регионах Дагестан, Чеченская респ. - 1%) пример расчета: доход
        в год 1млн руб. Налог с этого = 60т.р, но ИП в начале года оплатил
        пенсионные отчисления ~ 40. Соответственно он может уменьшить свой налог
        на сумму пенсионных платежей. 60-40 = 20т.р доплата.
      </li>
    </ul>
    <p style="margin-bottom: 10px">
      Налоги уменьшены, будущая пенсия копится, работаете в легальном статусе,
      более привлекательны на рынке грузоперевозок, так как для крупных компаний
      важно работать с ИП, а не с физиками, плюсы для вас и для нас. С нашей
      стороны
      <strong
        >полное сопровождение вас от открытия ИП до сдачи деклараций!</strong
      >
    </p>
    <p style="margin-bottom: 30px">Обращайтесь к своему диспетчеру.</p>
    <div>
      <a
        target="_blank"
        class="link__openip"
        href="https://xn--80ahdano6akfei4d3f.su/ip-voditel"
        >ОФОРМИТЬ ИП</a
      >
      <button @click="closeModal" class="modal__button__ok">
        ОЗНАКОМИЛСЯ - ЗАКРЫТЬ
      </button>
    </div>
  </div>
</template>

<script>
// Store
import { mapState } from "vuex";

export default {
  name: "ModalContent",
  props: {
    show: { type: Boolean, default: false }
  },
  computed: {
    ...mapState(["user"])
  },
  data() {
    return {
      localDate: localStorage.getItem("modalDate")
        ? localStorage.getItem("modalDate")
        : null
    };
  },
  methods: {
    closeModal() {
      let date = new Date();
      localStorage.setItem("modalDate", date.getTime());
      this.$emit("update:show", false);
    },
    showModal() {
      if (this.localDate) {
        let date = new Date();
        if (date.getTime() - this.localDate < 3600000 * 24) {
          this.$emit("update:show", false);
        }
      }
    }
  },
  created() {
    this.showModal();
  }
};
</script>

<style>
.link__openip {
  border: 2px solid #000000;
  border-radius: 20px;
  padding: 21px 91px;
  display: block;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin-bottom: 33px;
  max-width: 358px;
}
.modal__button__ok {
  display: block;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  letter-spacing: 0em;
  border: unset;
  background-color: unset;
  border-radius: unset;
  cursor: pointer;
  border-bottom: 1px solid #000000;
  padding-bottom: 3px;
}
.modal__content {
  padding: 62px 50px 62px 50px;
  background-color: #fffeda;
  border-radius: 20px;
}
.modal__content h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.modal__content p {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 1100px) {
  .modal__content {
    padding: 36px 22px 36px 22px;
  }
  .dialog__middle-innet {
    border-radius: 20px;
    width: 90%;
  }
}
@media (max-width: 540px) {
  .link__openip {
    padding: 13px 57px;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 13px;
    max-width: 229px;
  }
  .modal__content {
    padding: 36px 22px 36px 22px;
  }

  .dialog__middle-innet {
    border-radius: 20px;
    width: 90%;
  }

  .modal__content h3 {
    font-size: 13px;
    line-height: 15px;
  }
  .modal__content p,
  ul {
    font-size: 12px;
    line-height: 14px;
  }

  .modal__content ul {
    font-size: 12px;
    line-height: 14px;
  }

  .modal__button__ok {
    font-size: 13px;
    line-height: 15px;
  }
}
</style>
