export default {
  v: "5.7.6",
  fr: 30,
  ip: 0,
  op: 104,
  w: 1080,
  h: 1000,
  nm: "Ai Learn",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Layer 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: -0.328,
              s: [647.26, 581.492, 0],
              to: [-215.333, 162.667, 0],
              ti: [-107.417, 52.583, 0]
            },
            { t: 20.537, s: [491.135, 441.875, 0], h: 1 },
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: 42.072,
              s: [491.26, 442, 0],
              to: [102.365, -132.99, 0],
              ti: [376, 18, 0]
            },
            { t: 64.9560546875, s: [491.26, 586, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.016, 0.016, 0.016], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: -0.371,
              s: [74.186, 74.186, 100]
            },
            { t: 20.537, s: [28.207, 28.207, 100], h: 1 },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: 42.072,
              s: [28.207, 28.207, 100]
            },
            { t: 65, s: [49.602, 49.602, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.93],
                    [44.93, 0],
                    [0, 44.93],
                    [-44.93, 0]
                  ],
                  o: [
                    [0, 44.93],
                    [-44.93, 0],
                    [0, -44.93],
                    [44.93, 0]
                  ],
                  v: [
                    [81.352, 0],
                    [0, 81.352],
                    [-81.352, 0],
                    [0, -81.352]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.337254911661, 0.705882370472, 0.301960796118, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 900,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Layer 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: -0.328,
              s: [471.74, 594.492, 0],
              to: [-50.333, 5.336, 0],
              ti: [-227.667, -129.336, 0]
            },
            { t: 20.537, s: [633.573, 440, 0], h: 1 },
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: 42.072,
              s: [636.448, 440.375, 0],
              to: [44.958, 4.052, 0],
              ti: [119, -38.833, 0]
            },
            { t: 64.9560546875, s: [633.26, 585, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.016, 0.016, 0.016], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: -0.371,
              s: [50.831, 50.831, 100]
            },
            { t: 20.537, s: [25.748, 25.748, 100], h: 1 },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: 42.072,
              s: [25.748, 25.748, 100]
            },
            { t: 65, s: [72.957, 72.957, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.93],
                    [44.93, 0],
                    [0, 44.93],
                    [-44.93, 0]
                  ],
                  o: [
                    [0, 44.93],
                    [-44.93, 0],
                    [0, -44.93],
                    [44.93, 0]
                  ],
                  v: [
                    [81.352, 0],
                    [0, 81.352],
                    [-81.352, 0],
                    [0, -81.352]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.1254902035, 0.51372551918, 0.796078443527, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 900,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: -0.328,
              s: [640.26, 422.508, 0],
              to: [71, -25.667, 0],
              ti: [147, -16.333, 0]
            },
            { t: 20.537, s: [632.448, 569.133, 0], h: 1 },
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: 42.072,
              s: [634.385, 570.883, 0],
              to: [-210.198, 90.312, 0],
              ti: [-183.667, -140.5, 0]
            },
            { t: 64.9560546875, s: [631.26, 439.008, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.016, 0.016, 0.016], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: -0.328,
              s: [60.665, 60.665, 100]
            },
            { t: 20.537, s: [26.977, 26.977, 100], h: 1 },
            {
              i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: 42.072,
              s: [26.977, 26.977, 100]
            },
            { t: 65, s: [45.914, 45.914, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.93],
                    [44.93, 0],
                    [0, 44.93],
                    [-44.93, 0]
                  ],
                  o: [
                    [0, 44.93],
                    [-44.93, 0],
                    [0, -44.93],
                    [44.93, 0]
                  ],
                  v: [
                    [81.352, 0],
                    [0, 81.352],
                    [-81.352, 0],
                    [0, -81.352]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.890196084976, 0.317647069693, 0.141176477075, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 900,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: -0.328,
              s: [457.74, 422.508, 0],
              to: [73.333, -209, 0],
              ti: [461.667, -73, 0]
            },
            { t: 20.537, s: [492.74, 568.008, 0], h: 1 },
            {
              i: { x: 0.016, y: 1 },
              o: { x: 0.958, y: 0 },
              t: 42.072,
              s: [492.74, 568.008, 0],
              to: [0, 0, 0],
              ti: [-153, 19.167, 0]
            },
            { t: 64.9560546875, s: [492.74, 441.008, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.016, 0.016, 0.016], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: -1,
              s: [77.874, 77.874, 100]
            },
            { t: 20.537, s: [28.207, 28.207, 100], h: 1 },
            {
              i: { x: [0.016, 0.016, 0.016], y: [1, 1, 1] },
              o: { x: [0.958, 0.958, 0.958], y: [0, 0, 0] },
              t: 42.072,
              s: [28.207, 28.207, 100]
            },
            { t: 65, s: [72.957, 72.957, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -44.93],
                    [44.93, 0],
                    [0, 44.93],
                    [-44.93, 0]
                  ],
                  o: [
                    [0, 44.93],
                    [-44.93, 0],
                    [0, -44.93],
                    [44.93, 0]
                  ],
                  v: [
                    [81.352, 0],
                    [0, 81.352],
                    [-81.352, 0],
                    [0, -81.352]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.964705884457, 0.709803938866, 0.074509806931, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform"
            }
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false
        }
      ],
      ip: 0,
      op: 900,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
