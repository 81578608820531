export const paymentList = [
  {
    id: 1,
    color: "green",
    // status: "разрешено к оплате",
    status: "разрешено",
    statusDriver: "оплачено"
  },
  {
    id: 2,
    color: "orange",
    // status: "ждём оплаты клиентом",
    // status: "не оплачено",
    status: "ждём клиента",
    // statusDriver: "ожидает выплаты",
    statusDriver: "не оплачено"
  },
  {
    id: 3,
    color: "red",
    status: "нет документов",
    statusDriver: "запрещено"
  }
];
