<template>
  <div>
    <img src="../assets/images/empty.svg" alt="EmptyData" />
    <h1>Вы еще не доставили ни один заказ.</h1>
    <p>Получите первый заказ у диспетчера</p>
    <a class="pages__empty--phone" href="tel:+79264027446">+7 926 402-74-46</a>
    <a
      class="pages__empty--link"
      href="https://drive.google.com/file/d/1tMP_eev23gCtd1xy4uRZ81Uf1Kwv87pn/view"
      target="_blank"
    >
      Как пользоваться личным кабинетом</a
    >
  </div>
</template>

<script>
export default {
  name: "EmptyPage"
};
</script>

<style lang="scss">
.pages__empty {
  @include flex;
  bottom: 0;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  right: 0;

  h1 {
    font-size: 32px;
    margin-bottom: 49px;
  }

  p {
    font-size: 18px;
    margin-bottom: 11px;
  }

  &--phone {
    color: $color-basic;
    display: block;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 111px;
    text-decoration: none;
  }

  &--link {
    color: $color-blue-dark;
    font-size: 18px;
    text-decoration: underline;
  }
}

@media (max-width: 767.98px) {
  .pages__empty {
    img {
      width: 100%;
    }
  }
}
</style>
