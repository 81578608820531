export const arrayMounth = [
  { id: 1, name: "Январь", selected: false, disabled: true, pill: null },
  { id: 2, name: "Февраль", selected: false, disabled: true, pill: null },
  { id: 3, name: "Март", selected: false, disabled: true, pill: null },
  { id: 4, name: "Апрель", selected: false, disabled: true, pill: null },
  { id: 5, name: "Май", selected: false, disabled: true, pill: null },
  { id: 6, name: "Июнь", selected: false, disabled: true, pill: null },
  { id: 7, name: "Июль", selected: false, disabled: true, pill: null },
  { id: 8, name: "Август", selected: false, disabled: true, pill: null },
  { id: 9, name: "Сентябрь", selected: false, disabled: true, pill: null },
  { id: 10, name: "Октябрь", selected: false, disabled: true, pill: null },
  { id: 11, name: "Ноябрь", selected: false, disabled: true, pill: null },
  { id: 12, name: "Декабрь", selected: false, disabled: true, pill: null }
];
