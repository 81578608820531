<template>
  <div v-if="show" class="dialog__middle">
    <div class="dialog__middle-innet" ref="hiddenelem">
      <div class="dialog__middle-innet--close">
        <div @click="closeModal" class="cl-btn-2">
          <div>
            <div class="leftright"></div>
            <div class="rightleft"></div>
          </div>
        </div>
      </div>
      <div class="dialog__middle-innet--border"></div>
      <div class="dialog__middle-innet--content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalMiddle",
  props: {
    show: { type: Boolean, default: false },
    list: { type: Boolean, default: true }
  },
  data() {
    return {
      close: false
    };
  },
  methods: {
    closeModal() {
      let date = new Date();
      localStorage.setItem("modalDate", date.getTime());
      this.$emit("update:show", false);
    }
    // closeModalBack(e) {
    //   let hideen = this.$refs.hiddenelem;
    //   if (!hideen.contains(e.target)) {
    //     this.close = true;
    //     setTimeout(() => {
    //       this.$emit("update:show", false);
    //       this.close = false;
    //     }, 450);
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.dialog__middle {
  z-index: 9999;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
}
.cl-btn-2 {
  display: flex;
  justify-content: center;
}
.cl-btn-2 div {
  cursor: pointer;
  position: relative;
  height: 30px;
  width: 30px;
}
.cl-btn-2 .leftright {
  height: 1px;
  width: 21px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(45deg);
  transition: all 0.3s ease-in;
}
.cl-btn-2 .rightleft {
  height: 1px;
  width: 21px;
  position: absolute;
  margin-top: 24px;
  background-color: black;
  border-radius: 2px;
  transform: rotate(-45deg);
  transition: all 0.3s ease-in;
}
.cl-btn-2 .close-btn {
  margin: 54px 0 0 -5px;
  position: absolute;
  color: #337ab7;
  font-family: Tahoma, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
  opacity: 0;
}
.cl-btn-2 div:hover .leftright {
  transform: rotate(-45deg);
  background-color: #000;
}
.cl-btn-2 div:hover .rightleft {
  transform: rotate(45deg);
  background-color: #000;
}
.cl-btn-2 div:hover .close-btn {
  opacity: 1;
}
.dialog__middle-innet {
  height: max-content;
  background: white;
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  border-radius: 20px;

  &--close {
    height: 41px;
    right: 0px;
    position: absolute;
    top: 0;
    width: 45px;
    z-index: 1000;

    img {
      cursor: pointer;
      height: 29.33px;
      left: 10px;
      position: absolute;
      top: 6px;
      width: 29.33px;
      z-index: 1001;
    }
  }
  &--content {
    height: auto;
  }
}
</style>
