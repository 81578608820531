<template>
  <div class="report__title">
    <h2>ЗАКАЗЫ {{ mounth }} {{ year }}</h2>
    <div
      v-if="user.showOrderPrice"
      style="font-size: 16px"
      @click="changeShortTable"
    >
      <div v-if="!short" class="report__title--block">
        <div class="report__title--icon">Показать данные оплаты клиентом</div>
        <i class="far fa-arrow-to-right"></i>
      </div>
      <div v-if="short" class="report__title--block">
        <div class="report__title--icon">Скрыть данные оплаты клиентом</div>
        <i class="far fa-arrow-to-left"></i>
      </div>
    </div>
    <div @click="download" id="download__block">
      Скачать <img src="../../assets/images/download.svg" alt="Скачать" />
    </div>
    <div @click="printPage" id="print__block">
      Распечатать <img src="../../assets/images/print.svg" alt="Печать" />
    </div>
  </div>
</template>

<script>
// Store
import { mapState } from "vuex";

export default {
  name: "ReportTitle",
  props: {
    mounth: String,
    year: Number,
    short: Boolean
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    // Печать страницы
    printPage() {
      window.print();
    },
    download() {
      this.$emit("downloadExcel");
    },
    changeShortTable() {
      this.$emit("changeShortTable", this.short);
    }
  }
};
</script>

<style lang="scss">
.report__title {
  @include flex;
  @include flex-content-start;
  max-width: 1520px;
  margin-top: 20px;
  position: relative;

  h2 {
    margin: 0 !important;
  }

  div {
    cursor: pointer;
    margin-left: 10px;
  }

  &--block {
    @include flex;
  }

  &--icon {
    font-size: 9px;
    margin-right: 10px;
    width: 73px;
  }

  #download__block {
    @include flex;
    background: #ffffff;
    border: 1.47826px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 7.3913px;
    font-size: 13px;
    height: 32px;
    position: absolute;
    right: 130px;
    width: 114px;

    &:hover {
      background: $color-beige;
    }

    img {
      margin-left: 7px;
    }
  }

  #print__block {
    @include flex;
    background: #ffffff;
    border: 1.47826px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 7.3913px;
    font-size: 13px;
    height: 32px;
    position: absolute;
    right: -20px;
    width: 146px;

    &:hover {
      background: $color-beige;
    }

    img {
      margin-left: 7px;
    }
  }

  @media (max-width: 1560px) {
    #print__block {
      right: 0;
    }

    #download__block {
      right: 152px;
    }
  }

  @media (max-width: 767.98px) {
    #print__block,
    #download__block {
      display: none;
    }
  }
}
</style>
