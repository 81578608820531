<template>
  <div class="reports__table">
    <table
      :style="
        shortTable
          ? `max-width: 1850px; width: 1850px`
          : `max-width: 1540px; width: 1540px`
      "
    >
      <thead>
        <tr>
          <th rowspan="2" style="width: 40px">№</th>
          <th rowspan="2" style="width: 100px">Дата</th>
          <th rowspan="2" style="width: 90px">Заказ</th>
          <th rowspan="2" style="width: 130px">Клиент</th>
          <th rowspan="2" style="width: 300px">Адрес загрузки</th>
          <th v-if="user.showOrderPrice" rowspan="2" class="sum__parent">
            Сумма за заказ
          </th>
          <th
            v-if="shortTable && user.showOrderPrice"
            colspan="4"
            style="border-bottom: none; width: 310px"
          >
            Оплата счёта клиентом
          </th>
          <th v-if="user.showOrderPrice" rowspan="2" style="width: 100px">
            Оплачен клиентом?
          </th>
          <th colspan="2" style="border-bottom: none; width: 140px">
            Документы
          </th>
          <th v-if="user.showOrderPrice" rowspan="2" style="width: 120px">
            Решение к оплате
          </th>
          <th
            v-if="user.showOrderPrice"
            colspan="3"
            style="border-bottom: none; width: 310px"
          >
            Оплата исполнителю
          </th>
          <th rowspan="2" style="width: 100px">Диспетчер</th>
        </tr>
        <tr>
          <th
            v-if="shortTable && user.showOrderPrice"
            rowspan="2"
            style="border-left: none; width: 80px"
          >
            Выставлен счет №
          </th>
          <th
            v-if="shortTable && user.showOrderPrice"
            rowspan="2"
            style="width: 80px"
          >
            Дата<br />отправки
          </th>
          <th
            v-if="shortTable && user.showOrderPrice"
            rowspan="2"
            style="width: 70px"
          >
            Отсрочка<br />платежа
          </th>
          <th
            v-if="shortTable && user.showOrderPrice"
            rowspan="2"
            style="width: 80px"
          >
            Плановая дата оплаты
          </th>
          <th style="border-left: none; width: 70px">Клиент</th>
          <th style="width: 70px">GLP</th>
          <th
            v-if="user.showOrderPrice"
            rowspan="2"
            style="border-left: none; width: 100px"
          >
            Статус<br />выплаты
          </th>
          <th v-if="user.showOrderPrice" rowspan="2" class="sum__parent">
            Сумма<br />оплачено
          </th>
          <th v-if="user.showOrderPrice" rowspan="2" style="width: 100px">
            Дата<br />оплаты
          </th>
        </tr>
      </thead>
      <tbody v-if="orderStatusList.length > 0">
        <tr
          v-for="(order, idx) in orderStatusList"
          :key="order.id"
          :class="[
            selectedOrders.includes(order.id) ? `active` : ``,
            idx % 2 ? '' : ' striple'
          ]"
          @mouseover="hoverOnCopyInn(order.id)"
          @mouseleave="hoverOffCopyInn"
        >
          <td style="width: 40px">{{ idx + 1 }}</td>
          <td style="width: 100px">
            {{ order.formatedCreateDate }}
          </td>
          <td style="width: 90px">{{ order.prefix + order.id }}</td>
          <td style="width: 130px" :title="order.clientName">
            <div style="width: 120px; margin: 0 5px" class="nowrap">
              {{ order.clientName }}
            </div>
            <div
              style="margin: 0 5px; color: #999999; width: 120px"
              class="nowrap"
            >
              <small
                v-if="clientINN && orderClientId === order.id"
                title="ИНН организации"
                >{{ order.clientInn }}</small
              >
            </div>
            <div
              v-if="clientINN && orderClientId === order.id"
              class="copyInnIcon"
            >
              <img
                @click="copyInnData(order.id, `${order.clientInn}`)"
                src="../../assets/images/copy.svg"
                alt="Копировать"
                title="Копировать ИНН организации"
              />
            </div>
            <div style="position: relative">
              <div v-if="copydata.includes(order.id)" class="copydata">
                Скопировано в буфер
              </div>
            </div>
          </td>
          <td style="width: 300px" :title="order.routeTitle">
            <div style="width: 290px; margin: 0 5px" class="nowrap">
              {{ order.firstAddress }}
            </div>
          </td>
          <td v-if="user.showOrderPrice" class="sum__parent">
            <div
              v-if="order.sumDriver && order.status === 3"
              class="sum__block--normal"
            >
              {{ order.sumDriver | rounding }}
            </div>
          </td>
          <td v-if="shortTable && user.showOrderPrice" style="width: 80px">
            <div v-if="order.score">
              {{ order.score }}
            </div>
          </td>
          <td v-if="shortTable && user.showOrderPrice" style="width: 80px">
            {{ order.scoreDate }}
          </td>
          <td v-if="shortTable && user.showOrderPrice" style="width: 70px">
            <div v-if="order.deferredPayment">
              {{ order.deferredPayment }}
            </div>
          </td>
          <td v-if="shortTable && user.showOrderPrice" style="width: 80px">
            {{ order.dateClientPay }}
          </td>
          <td v-if="user.showOrderPrice" style="width: 100px">
            <div
              :class="order.paymentClientStatusData.class"
              style="width: 88px"
            >
              {{ order.paymentClientStatusData.name }}
            </div>
          </td>
          <td>
            <span :class="order.orderDocClient.color">
              {{ order.orderDocClient.name }}
            </span>
          </td>
          <td>
            <span :class="order.orderDocGlp.color">
              {{ order.orderDocGlp.name }}
            </span>
          </td>
          <td v-if="user.showOrderPrice" style="width: 120px">
            <div style="width: 108px" :class="order.paymentStatus.color">
              {{ order.paymentStatus.status }}
            </div>
          </td>
          <td v-if="user.showOrderPrice">
            <div :class="order.paymentDriverStatus.color">
              {{ order.paymentDriverStatus.statusDriver }}
            </div>
          </td>
          <td v-if="user.showOrderPrice" class="sum__parent">
            <div v-if="order.paymentSum" class="sum__block--normal">
              {{ order.paymentSum | rounding }}
            </div>
          </td>
          <td v-if="user.showOrderPrice">{{ order.dateDriverPay }}</td>
          <td style="width: 102px">
            <div
              style="width: 90px; justify-content: center !important"
              class="nowrap"
            >
              {{ order.dispatcherName }}
            </div>
          </td>
        </tr>
        <tr v-if="user.showOrderPrice">
          <td colspan="5" class="sum">ИТОГО СУММА ЗАКАЗОВ</td>
          <td class="sum__parent">
            <div class="sum__block">
              {{ getOrdersSum | rounding }}
            </div>
          </td>
          <td :colspan="shortTable ? 9 : 5" class="sum">ИТОГО ОПЛАЧЕНО</td>
          <td class="sum__parent">
            <div class="sum__block">
              {{ getDriverSum | rounding }}
            </div>
          </td>
          <td colspan="3"></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="19" class="empty__data">
            <span>В этом месяце нет выполненных заказов</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// Store
import { mapState } from "vuex";

export default {
  name: "ReportOrdersTable",
  props: {
    shortTable: Boolean,
    orderStatusList: Array,
    selectedOrders: Array,
    getOrdersSum: Number,
    getDriverSum: Number
  },
  data() {
    return {
      copydata: [],
      orderClientId: null,
      clientINN: false
    };
  },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else if (arr[1].length === 2) {
            tail = "." + arr[1];
          } else {
            tail =
              "." +
              (Math.round(+arr[1].slice(0, 3) / 5) * 5).toString().slice(0, 2);
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  computed: {
    ...mapState(["user"])
  },
  methods: {
    // Показываем ИНН и кнопку копирования при наведении
    hoverOnCopyInn(id) {
      this.clientINN = true;
      this.orderClientId = id;
    },
    hoverOffCopyInn() {
      this.clientINN = false;
      this.orderClientId = null;
    },
    // Копирование ИНН в буфер
    copyInnData(id, copy) {
      this.copydata.push(id);
      const copytext = document.createElement("textarea");
      copytext.style = "left: -9999px; position: absolute";
      copytext.value = copy;
      document.body.appendChild(copytext);
      copytext.select();
      document.execCommand("copy");
      setTimeout(() => {
        this.copydata = [];
        copytext.remove();
      }, 2000);
    }
  }
};
</script>

<style lang="scss">
.reports__table {
  max-width: 4000px !important;
  overflow-x: auto !important;
  padding: 15px 0 10px 0;

  table {
    th {
      height: auto !important;
      padding: 5px;
    }

    td {
      text-align: center;
      position: relative;

      div {
        margin: 0 5px;
      }

      .route {
        background: $color-grey;
        border: 1px solid $color-grey-light;
        padding-left: 5px;
        position: absolute;
        right: -405px;
        text-align: left;
        top: 0;
        width: 400px;
        z-index: 10;

        ul {
          list-style: none;
        }
      }
    }
  }
}

.copyInnIcon {
  position: absolute;
  top: 7px;
  right: -1px;

  img:hover {
    opacity: 0.6;
  }
}

.copydata {
  @include flex;
  background: $color-grey-light;
  border-radius: 8px;
  color: $color-black-light;
  font-size: 14px;
  padding: 8px 10px;
  position: absolute;
  right: -176px;
  top: -30px;
  z-index: 10;
}
</style>
