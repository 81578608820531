<template>
  <div>
    <the-header></the-header>
    <main class="main">
      <the-report></the-report>
    </main>
    <modalMiddle v-if="!mountedShow" :show.sync="show">
      <modalContent :show.sync="show"></modalContent>
    </modalMiddle>
    <the-footer></the-footer>
  </div>
</template>

<script>
// Store
import { mapState } from "vuex";

import TheHeader from "../components/layout/the-header.vue";
import TheFooter from "../components/layout/the-footer.vue";
import TheReport from "../components/the-report.vue";
import modalMiddle from "../components/ui/modal-middle.vue";
import modalContent from "../components/ui/modal-content.vue";

export default {
  name: "Home",

  components: {
    TheHeader,
    TheFooter,
    TheReport,
    modalMiddle,
    modalContent
  },
  computed: {
    ...mapState(["user", "mounted"])
  },
  data() {
    return {
      show: true,
      mountedShow: true
    };
  },
  watch: {
    mounted() {
      this.mountedShow = this.mounted;
    }
  }
};
</script>
